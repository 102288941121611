@font-face {
  font-family: 'icomoon';
  src:  url('icons/icomoon.eot?8b3ptr');
  src:  url('icons/icomoon.eot?8b3ptr#iefix') format('embedded-opentype'),
    url('icons/icomoon.ttf?8b3ptr') format('truetype'),
    url('icons/icomoon.woff?8b3ptr') format('woff'),
    url('icons/icomoon.svg?8b3ptr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-down:before {
  content: "\e90c";
}
.icon-chevron-left:before {
  content: "\e901";
}
.icon-chevron-right:before {
  content: "\e90d";
}
.icon-chevron-up:before {
  content: "\e902";
}
.icon-download:before {
  content: "\e915";
}
.icon-maximize:before {
  content: "\e926";
}
.icon-minimize-2:before {
  content: "\e929";
}
.icon-minus:before {
  content: "\e900";
}
.icon-more-vertical:before {
  content: "\e92c";
}
.icon-plus:before {
  content: "\e92e";
}
.icon-refresh-cw:before {
  content: "\e92f";
}
.icon-x:before {
  content: "\e93c";
}
