@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni&family=Poppins:wght@400;600&display=swap');
@import url('./icons.css');

* {
  box-sizing: border-box;
}

html, body {
  font-size: 3vmin;
  font-weight: 400;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #212121;
  max-width: 425px;
}

.exit {
  display: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

button:hover {
  cursor: pointer;
  opacity: .8;
}

button:disabled {
  opacity: .2;
  pointer-events: none;
}

h1 {
  font-family: 'Libre Bodoni', serif;
  font-weight: 400;
  font-size: 5vmin;
}

h2 {
  font-weight: 400;
  font-size: 3.5vmin;
}

h3 {
  font-family: 'Libre Bodoni', serif;
  font-weight: 400;
  font-size: 3vmin;
}

h4 {
  font-size: 1.75vmin;
  font-weight: 400;
}

.my-look-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 4vmin;
  transition: all .3s ease;
  position: relative;
}

.my-look-container .logo {
  width: 40%;
  position: absolute;
  top: 4vmin;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 300px;
  transition: all .4s ease;
}

.my-look-container .logo.animate {
  transform: translate(-50%, -5vh);
  opacity: 0;
}


.my-look-start {
  background: #fff3fe;
  border-radius: 12px;
  padding: 4vmin;
  margin: auto 0;
  transform: translateY(-25%);
  transition: all .4s ease;
}

.my-look-start.animate {
  transform: translateY(-10%);
  opacity: 0;
}

.gradient-btn {
  border-radius: 20px;
  padding: 2vmin 4vmin;
  background: #fe9d93;
  background: linear-gradient(to right, #fe9d93, #e689f5, #84b0fc, #66bcff);
  color: #fff;
}

.my-look-start button {
  margin: 4vmin 0 0;
}

.my-look-camera-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.8);
}

.my-look-camera {
  width: 80%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #000;
  background: #fff;
  border-radius: 12px;
  padding: 4vmin;
  transition: all .3s ease;
}

.my-look-camera-container.animate .my-look-camera {
  transform: translate(-50%, 0);
  opacity: 0;
}

.input-video-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.5%;
  position: relative;
  text-align: center;
}

.input-video-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 80%;
  transform: translate(-50%,-50%);
  object-fit: contain;
  z-index: 2;
}

.input-video-container video {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
  margin: 0;
}

.input-video-loader {
  background-color: #eee;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.input-video-loader h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.input-video-btn-group {
  display: flex;
  flex-direction: column;
  margin: 4vmin 0 0;
}

.input-video-btn-group .cancel-btn {
  margin: 2vmin 0 0;
}