.my-look-scene {
  position: absolute;
  top: 10vh;
  left: 0;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  transition: all .4s ease;
}

.my-look-scene.animate {
  left: 50%;
  opacity: 0;
}

.my-look-header {
  flex: initial;
  height: 10vh;
  display: flex;
  align-items: center;
  gap: 4vmin;
  padding: 0 4vmin;
}

.my-look-header button {
  font-size: 4vmin;
  line-height: 4vmin;
  padding: 2vmin;
  border-radius: 12px;
  background-color: #000;
  color: #fff;
}

.my-look-header button:nth-of-type(2) {
  margin: 0 0 0 auto;
}

.my-look-canvas {
  width: 100%;
  flex: initial;
  height: 0;
  padding-bottom: 56.5%;
  position: relative;
  user-select: none;
}

.my-look-canvas canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.my-look-items-container {
  flex: 1;
  overflow: auto;
  padding: 0 0 4vmin;
}

.my-look-items {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4vmin;
  padding: 4vmin 0 0;
}

.my-look-items.added {
  background-color: #eee;
  background: #fe9d93;
  background: linear-gradient(to right, #fe9d93, #e689f5, #84b0fc, #66bcff);
}

.my-look-items.added h3 {
  color: #fff;
}

.my-look-items h3 {
  flex: initial;
  font-size: 4vmin;
}

.my-look-items-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 4vmin 4vmin;
}

.my-look-items-list li {
  flex: initial;
  width: 29%;
  background: #fff;
  border-radius: 12px;
  padding: 0 0 2vmin;
  margin: 0 2.1% 4vmin;
  border: 1px solid #eee;
  position: relative;
}

.my-look-items-list li h4 {
  padding: 2vmin 2vmin 0;
  font-size: 2.5vmin;
}

.my-look-items-list li h5 {
  padding: 0 2vmin;
  margin: 0;
  font-size: 2.25vmin;
  color: #999;
  font-weight: 400;
}

.my-look-items-list li .remove-btn {
  position: absolute;
  top: -2vmin;
  right: -2vmin;
  background-color: rgb(255, 0, 119);
  color: #fff;
  height: 5vmin;
  width: 5vmin;
  font-size: 3.5vmin;
  line-height: 5vmin;
  border-radius: 100%;
}

.my-look-items-list li .img-container {
  background-color: #f2f2f2;
  width: 100%;
  padding: 2vmin;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.my-look-items-list li .img-container .img {
  width: 50%;
  height: 0;
  padding-bottom: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  margin: 0 auto;
}

.my-look-items-list li .scale-container {
  overflow: hidden;
  margin: 2vmin 0 0;
}

.slider {
  -webkit-appearance: none; 
  appearance: none;
  width: 90%;
  height: 2vmin;
  background: #ddd; 
  outline: none; 
  opacity: 0.7; 
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin: 0 auto;
  border-radius: 6px;
}

.slider:hover {
  opacity: 1; 
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;  
  appearance: none;
  width: 4vmin;
  height: 4vmin;
  border-radius: 100%;  
  background: #da8df6;
  cursor: pointer;  
}

.slider::-moz-range-thumb {
  width: 4vmin;
  height: 4vmin;
  border-radius: 100%;  
  background: #da8df6;
  cursor: pointer;  
}